//
//  Amasty Search Pro
//  ____________________________________________

//
//  Variables
//  ______________________________________________

//
//  Colors
//  ----------------------------------------------

$amsearch-item__background__hover: #f4f5fa;
$amsearch-product-sought__background: #292E47;
$amsearch-border__color: #eeeeee;
$amsearch-secondary__color: #292E47;

$amsearch-icon__background__compare: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAyMCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUgMTRWOS4zNjk3OUgwVjE0SDVaTTEyLjQ2MDkgMEg3LjVWMTRIMTIuNDYwOVYwWk0yMCA0LjcwMzEySDE1VjE0SDIwVjQuNzAzMTJaIiBmaWxsPSIjNkU3MTZFIi8+Cjwvc3ZnPgo=);
$amsearch-icon__background__wishlist: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAyMCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjA2MTIgMC40NzczMUMxNS43NjIgLTAuMTAzNzYzIDE0LjQ0OTcgLTAuMTU0MjkxIDEzLjEyNDUgMC4zMjU3MjZDMTEuNzk5MiAwLjc4MDQ3OSAxMC44Mzc3IDEuNjM5NDYgMTAuMjQwMSAyLjkwMjY2QzEwLjE4ODEgMy4wMDM3MiAxMC4xMzYxIDMuMTA0NzcgMTAuMDg0MiAzLjIwNTgzQzEwLjA1ODIgMy4zMDY4OCAxMC4wMzIyIDMuNDIwNTcgMTAuMDA2MiAzLjU0Njg5QzkuOTgwMjMgMy40MjA1NyA5Ljk0MTI1IDMuMzA2ODggOS44ODkyOCAzLjIwNTgzQzkuODYzMyAzLjEwNDc3IDkuODI0MzIgMy4wMDM3MiA5Ljc3MjM1IDIuOTAyNjZDOS4xNzQ2OCAxLjYzOTQ2IDguMjEzMjMgMC43ODA0NzkgNi44ODc5NyAwLjMyNTcyNkM1LjU2MjcyIC0wLjE1NDI5MSA0LjI1MDQ2IC0wLjEwMzc2MyAyLjk1MTE5IDAuNDc3MzFDMS42Nzc5MSAxLjA4MzY1IDAuNzk0NDA5IDIuMDQzNjggMC4zMDA2ODggMy4zNTc0MUMtMC4xNjcwNDkgNC42NzExNCAtMC4wODkwOTI2IDUuOTU5NjEgMC41MzQ1NTYgNy4yMjI4MUMwLjgyMDM5NSA3Ljg1NDQxIDEuNDcwMDMgOC43MjYwMiAyLjQ4MzQ2IDkuODM3NjRDMy41MjI4NyAxMC45MjQgNC42MDEyNiAxMS45OTc3IDUuNzE4NjMgMTMuMDU4OEM2LjgzNiAxNC4xMTk5IDcuODIzNDUgMTUuMDQyIDguNjgwOTYgMTUuODI1MkM5LjU2NDQ2IDE2LjYwODQgMTAuMDA2MiAxNyAxMC4wMDYyIDE3QzEwLjAwNjIgMTcgMTAuMDA2MiAxNi45ODc0IDEwLjAwNjIgMTYuOTYyMUMxMC4wMDYyIDE2Ljk4NzQgMTAuMDA2MiAxNyAxMC4wMDYyIDE3QzEwLjAwNjIgMTcgMTAuNDM1IDE2LjYwODQgMTEuMjkyNSAxNS44MjUyQzEyLjE3NiAxNS4wNDIgMTMuMTc2NCAxNC4xMTk5IDE0LjI5MzggMTMuMDU4OEMxNS40MTEyIDExLjk5NzcgMTYuNDc2NiAxMC45MjQgMTcuNDkgOS44Mzc2NEMxOC41Mjk0IDguNzI2MDIgMTkuMTkyIDcuODU0NDEgMTkuNDc3OSA3LjIyMjgxQzIwLjEwMTUgNS45NTk2MSAyMC4xNjY1IDQuNjcxMTQgMTkuNjcyOCAzLjM1NzQxQzE5LjIwNSAyLjA0MzY4IDE4LjMzNDUgMS4wODM2NSAxNy4wNjEyIDAuNDc3MzFaIiBmaWxsPSIjNkU3MTZFIi8+Cjwvc3ZnPgo=);
$amsearch-icon__background__close: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgMWwxMCAxMG0wLTEwTDEgMTEiIHN0cm9rZT0iI0IyQjJCMiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPjwvc3ZnPg==);
$amsearch-icon__background__loupe: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1Ni45NjYgNTYuOTY2Ij48cGF0aCBkPSJNNTUuMTQ2IDUxLjg4N0w0MS41ODggMzcuNzg2QTIyLjkyNiAyMi45MjYgMCAwIDAgNDYuOTg0IDIzYzAtMTIuNjgyLTEwLjMxOC0yMy0yMy0yM3MtMjMgMTAuMzE4LTIzIDIzIDEwLjMxOCAyMyAyMyAyM2M0Ljc2MSAwIDkuMjk4LTEuNDM2IDEzLjE3Ny00LjE2MmwxMy42NjEgMTQuMjA4Yy41NzEuNTkzIDEuMzM5LjkyIDIuMTYyLjkyLjc3OSAwIDEuNTE4LS4yOTcgMi4wNzktLjgzN2EzLjAwNCAzLjAwNCAwIDAgMCAuMDgzLTQuMjQyek0yMy45ODQgNmM5LjM3NCAwIDE3IDcuNjI2IDE3IDE3cy03LjYyNiAxNy0xNyAxNy0xNy03LjYyNi0xNy0xNyA3LjYyNi0xNyAxNy0xN3oiIGZpbGw9IiNhOWE5YTkiLz48L3N2Zz4=);
$amsearch-icon__background__loader: url(data:image/gif;base64,R0lGODlhEAALAPQAAP///wAAANra2tDQ0Orq6gYGBgAAAC4uLoKCgmBgYLq6uiIiIkpKSoqKimRkZL6+viYmJgQEBE5OTubm5tjY2PT09Dg4ONzc3PLy8ra2tqCgoMrKyu7u7gAAAAAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCwAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQJCwAAACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQJCwAAACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQJCwAAACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAkLAAAALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkECQsAAAAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAkLAAAALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkECQsAAAAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA);
$amsearch-icon__background__arrow-right: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNDUxLjg0NiA0NTEuODQ3Ij48cGF0aCBkPSJNMzQ1LjQ0MSAyNDguMjkyTDE1MS4xNTQgNDQyLjU3M2MtMTIuMzU5IDEyLjM2NS0zMi4zOTcgMTIuMzY1LTQ0Ljc1IDAtMTIuMzU0LTEyLjM1NC0xMi4zNTQtMzIuMzkxIDAtNDQuNzQ0TDI3OC4zMTggMjI1LjkyIDEwNi40MDkgNTQuMDE3Yy0xMi4zNTQtMTIuMzU5LTEyLjM1NC0zMi4zOTQgMC00NC43NDggMTIuMzU0LTEyLjM1OSAzMi4zOTEtMTIuMzU5IDQ0Ljc1IDBsMTk0LjI4NyAxOTQuMjg0YzYuMTc3IDYuMTggOS4yNjIgMTQuMjcxIDkuMjYyIDIyLjM2NiAwIDguMDk5LTMuMDkxIDE2LjE5Ni05LjI2NyAyMi4zNzN6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iYWN0aXZlLXBhdGgiIGRhdGEtb2xkX2NvbG9yPSIjYTNhM2EzIiBmaWxsPSIjYTNhM2EzIi8+PC9zdmc+) no-repeat center;

//
//  Indents
//  ----------------------------------------------

$amsearch__indent: 10px;
$amsearch__indent__m: $amsearch__indent * 2 - $amsearch__indent / 2;
$amsearch__indent__l: $amsearch__indent * 2;
$amsearch__indent__xl: $amsearch__indent + $amsearch__indent__m;

$amsearch-slider-arrow__size: 40px;
$amsearch-sidebar__width: 250px;

//
//  Base Styles
//  ______________________________________________

//
//  Mixins
//  _____________________________________

@mixin am-scrollbar(
    $color: #adadad,
    $second-color: #f0f0f0,
    $width: 4px,
    $shadow-color: #fff
) {
    & {
        overflow: hidden;
        overflow-y: auto;
        scrollbar-color: $color rgba(255, 255, 255, .2);
        scrollbar-width: thin;
    }

    &::-webkit-scrollbar {
        background: $second-color;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: $width;
        background: $color;
        cursor: pointer;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px $shadow-color;
    }

    &::-webkit-scrollbar {
        width: $width;
    }

    &::-webkit-scrollbar-thumb {
        width: $width;
    }

    ::-webkit-scrollbar-track {
        width: $width;
    }
}

.am-word-break {
    & {
        word-break: break-word;
        word-wrap: break-word;
    }

    .ie11 & {
        word-break: break-all;
    }
}

//
//  Common
//  ----------------------------------------------

.amsearch-form-block {
    & {
        max-width: $amsearch-sidebar__width;
        align-items: center;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        position: relative;
        transition: .3s;
    }

    &.-opened {
        z-index: 98;
    }
}

.header.content {
    flex-wrap: wrap;
}

.amsearch-loader-block {
    background: $amsearch-icon__background__loader no-repeat center;
    bottom: 0;
    height: 11px;
    margin: auto $amsearch__indent / 2 auto 0;
    position: absolute;
    right: 40px;
    top: 0;
    width: 16px;
    z-index: 1000;
}

.amsearch-message-block {
    & {
        align-items: center;
        background: #fafafa;
        display: flex;
        font-size: 18px;
        justify-content: center;
        line-height: 25px;
        margin: $amsearch__indent__l;
        padding: $amsearch__indent__l;
        text-align: center;
        width: 100%;
    }

    b {
        padding: 0 0 0 5px;
    }
}

.amsearch-link {
    & {
        color: $amsearch-secondary__color;
        text-decoration: none;
    }

    &:hover {
        color: #006bb4;
        text-decoration: underline;
    }

    &:active {
        color: #004c80;
    }
}

.amsearch-wrapper-block {
    box-sizing: border-box;
    display: inline-block;
    margin: $amsearch__indent 0 0 0;
    order: 1;
    padding: $amsearch__indent__l 0;
    position: relative;
    width: 100%;
}

.nav-sections .navigation {
    z-index: 2;
}

.page-header .amsearch-wrapper-block:before {
    background: $amsearch-border__color;
    content: '';
    height: 1px;
    margin: 0 -$amsearch__indent__m;
    position: absolute;
    top: 0;
    width: calc(100% + #{$amsearch__indent__m * 2});
}

.amsearch-emptysearch-cms {
    & {
        background: #fbfcfe;
        color: #1f1b1b;
        padding: 30px;
    }

    & > .amsearch-content {
        @extend .am-word-break;
    }

    .amsearch-content > .amsearch-title {
        font-size: 38px;
        font-weight: 800;
        margin: 0 0 $amsearch__indent__l;
    }

    .amsearch-text {
        font-size: 20px;
        font-weight: 300;
        line-height: 28px;
    }

    .amsearch-contacts-block {
        font-size: 14px;
        margin-top: 30px;
    }

    .amsearch-image-block {
        margin-top: 30px;
    }

    .amsearch-contacts-block > .amsearch-title {
        margin-bottom: 5px;
    }
}

.amsearch-highlight {
    color: var(--amsearch-color-highlight, $amsearch-product-sought__background);
    font-weight: 700;
}

//
//  Mobile
//  --------------------------------------------

@include min-screen($screen__s) {
    // Extend Magento Page builder product carousel
    .catalogsearch-result-index .amsearch-pagebuilder-carousel .product-item-info {
        margin: 0 auto;
        width: 240px;
    }
}

//
//  Tablet
//  --------------------------------------------

@include min-screen($screen__m) {
    .action.compare {
        padding: 0 $amsearch__indent;
    }

    .amsearch-wrapper-block {
        order: initial;
        width: auto;
    }

    .page-header .amsearch-wrapper-block {
        & {
            float: right;
            margin: 0;
            padding: 0;
            width: auto;
        }

        &:before {
            content: none;
        }
    }

    .amsearch-loader-block {
        right: 30px;
    }

    .amsearch-emptysearch-cms {
        & {
            padding: 40px;
        }

        .amsearch-content > .amsearch-title {
            font-size: 54px;
        }

        .amsearch-text {
            font-size: 22px;
        }
    }
}

//
//  Desktop
//  --------------------------------------------

@include min-screen($screen__l) {
    .amsearch-emptysearch-cms {
        & {
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding: 40px 70px;
        }

        > .amsearch-item {
            max-width: 50%;
        }

        > .amsearch-content {
            padding-right: $amsearch__indent;
        }

        .amsearch-content > .amsearch-title {
            font-size: 72px;
            margin: 0 0 $amsearch__indent__l;
        }

        .amsearch-text {
            font-size: 28px;
            line-height: 33px;
        }

        .amsearch-contacts-block {
            font-size: 22px;
            margin-top: 45px;
        }

        .amsearch-image-block {
            margin-top: 0;
        }

        .amsearch-contacts-block > .amsearch-title {
            margin-bottom: $amsearch__indent;
        }
    }
}

//
//  Search Buttons Styles
//  ______________________________________________

//
//  Colors
//  ----------------------------------------------

$amsearch-button__full-close__color: #f5f5f5;

//
//  Common
//  ----------------------------------------------

.amsearch-button {
    & {
        cursor: pointer;
        display: inline-block;
        transition: .3s;
    }

    &.-icon {
        & {
            background-position: center;
            background-repeat: no-repeat;
            background-size: 13px;
        }

        &:hover,
        &:focus {
            filter: brightness(.5);
        }
    }

    &.-clear {
        & {
            background-color: transparent;
            border: none;
            box-shadow: none;
            padding: 0;
        }

        &:active,
        &:hover,
        &:focus {
            border: none;
            box-shadow: none;
        }
    }

    &.-close {
        background-image: $amsearch-icon__background__close;
        border: 0;
        padding: 16px;
    }

    &.-loupe {
        background-image: $amsearch-icon__background__loupe;
        border: 0;
        box-shadow: none;
        height: 20px;
        padding: 0;
        width: 20px;
    }

    &.-wishlist,
    &.-compare {
        background-size: 18px;
    }

    &.-wishlist {
        background-image: $amsearch-icon__background__wishlist;
        height: 20px;
        width: 20px;
    }

    &.-compare {
        background-image: $amsearch-icon__background__compare;
        height: 20px;
        width: 20px;
    }

    &.-disabled {
        opacity: .7;
        pointer-events: none;
    }

    &.-primary {
        & {
            background: $amsearch-secondary__color;
            border: 1px solid $amsearch-secondary__color;
            border-radius: 0;
            color: $amsearch-secondary__color;
            letter-spacing: -.015em;
            transition: .3s;
        }

        &:hover {
            background: $amsearch-secondary__color;
        }

        &:active {
            background: $amsearch-secondary__color;
        }
    }

    &.-search {
        & {
            background-color: var(--amsearch-color-search_button, $amsearch-secondary__color);
            border-color: var(--amsearch-color-search_button, $amsearch-secondary__color);
            color: var(--amsearch-color-search_button_text, #fff);
        }

        &:hover {
            background: var(--amsearch-color-search_button-hover, darken($amsearch-secondary__color, 5%));
            border-color: var(--amsearch-color-search_button-hover, darken($amsearch-secondary__color, 5%));
        }

        &:active {
            background: var(--amsearch-color-search_button-focus, darken($amsearch-secondary__color, 10%));
            border-color: var(--amsearch-color-search_button-focus, darken($amsearch-secondary__color, 10%));
        }
    }

    &.-full-close {
        & {
            align-items: center;
            background-color: $amsearch-button__full-close__color;
            border-color: $amsearch-button__full-close__color;
            border-radius: 50%;
            display: flex;
            font-size: 10px;
            height: 32px;
            justify-content: center;
            padding: 0;
            width: 32px;
        }

        &:after {
            background: $amsearch-icon__background__close;
            background-repeat: no-repeat;
            content: '';
            display: inline-block;
            height: 12px;
            width: 12px;
        }

        &:hover {
            background-color: darken($amsearch-button__full-close__color, 5%);
        }

        &:active {
            background-color: darken($amsearch-button__full-close__color, 10%);
        }
    }
}

//
//  Tablet
//  --------------------------------------------

@include min-screen($screen__m) {
    .amsearch-button {
        &.-close:hover,
        &.-close:hover {
            filter: brightness(0%);
        }
    }
}

//
//  Slick Slider Extend
//  _____________________________________

//
//  Variables
//  -------------------------------------

$amsearch-slider-arrow: #fff url('data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxMSAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjU0NTQgOS4zNzY2OEwyLjE5ODk1IDAuNDA5MThMMC43NDIyNjEgMS44NDg5Mkw3Ljc1MDEzIDkuMzc2NjhMMC41NDU0MSAxNy4xNTEzTDEuOTYyNzMgMTguNTkxTDEwLjA3MyA5LjkxMTQ0TDEwLjU0NTQgOS4zNzY2OFoiIGZpbGw9IiM5OTk5OTkiLz4KPC9zdmc+Cg==') center no-repeat;
$amsearch-slider-dots__size: 6px;
$amsearch-slider-dots__color__active: #ff5502;

//
//  Common
//  -------------------------------------

.amsearch-slider-section {
    & {
        box-sizing: border-box;
        display: flex;
    }

    .amsearch-item {
        &:hover,
        &.selected {
            background-color: transparent;
        }

        .amsearch-image {
            pointer-events: auto;
            width: 100%;
        }
    }

    &.slick-slider {
        padding: 0;
    }

    .slick-dots {
        & {
            background: inherit;
            border: none;
            top: 100%;
        }

        > li[role='presentation'] {
            & {
                align-items: center;
                border: none;
                display: inline-flex;
                height: $amsearch-slider-dots__size;
                justify-content: center;
                margin: 0 6px;
                padding: 0;
                text-align: inherit;
                white-space: inherit;
                width: $amsearch-slider-dots__size;
            }

            &:hover,
            &.selected {
                background: inherit;
            }

            &.slick-active button {
                background: $amsearch-slider-dots__color__active;
                border-color: $amsearch-slider-dots__color__active;
            }
        }

        > li[role='presentation'] button {
            & {
                background: #fff;
                border: 1px solid $amsearch-border__color;
                border-radius: 100%;
                height: 100%;
                padding: 0;
                width: 100%;
            }

            &:before {
                content: none;
            }
        }
    }

    button.slick-arrow {
        background: $amsearch-slider-arrow !important; // Slick slider already has an important directive here. Override
        border-radius: 0;
        bottom: 0;
        height: $amsearch-slider-arrow__size;
        margin: auto 0;
        top: 0;
        width: $amsearch-slider-arrow__size;
        z-index: 2;
    }
}

//
//  Desktop
//  --------------------------------------------

@include min-screen($screen__m) {
    .amsearch-slider-section {
        & {
            max-width: 575px;
        }

        &.slick-slider {
            padding: 0 40px;
        }
    }
}

//
//  Search Popup Product List Styles
//  ______________________________________________

//
//  Common
//  ----------------------------------------------

#amasty-shopby-product-list {
    max-width: 100%;
    order: 10;
}

.amsearch-products-section {
    & {
        align-content: flex-start;
        border-left: none;
        border-top: 1px solid $amsearch-border__color;
        box-sizing: border-box;
        padding: $amsearch__indent__l 0 0;
        position: relative;
        transition: .1s;
        width: 100%;
    }

    .amsearch-sku-block {
        padding: 0 0 $amsearch__indent;
    }

    .amsearch-link {
        & {
            color: var(--amsearch-color-text, $amsearch-product-sought__background);
        }

        &:hover {
            color: var(--amsearch-color-text-hover, $amsearch-product-sought__background);
        }
    }

    .amsearch-block-header {
        padding: 0 $amsearch__indent__l $amsearch__indent__xl;
    }

    .amsearch-item .product-item-description,
    .amsearch-item .item-description {
        word-break: keep-all;
    }

    .product-item-description {
        color: #999;
        font-size: 1.2rem;
        margin: 0;
        padding: 0 0 $amsearch__indent;
    }

    .amsearch-product-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
    }

    .amsearch-item {
        & {
            align-content: flex-start;
            box-sizing: border-box;
            display: flex;
            margin: 0;
            padding: $amsearch__indent__l;
            position: relative;
        }

        &:hover {
            background: var(--amsearch-color-hover, $amsearch-item__background__hover);
            z-index: 8;
        }

        &:not(:hover) .dropdown {
            display: none;
        }
    }

    .product-item-details {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        min-height: 100px;
    }

    .amsearch-wrapper-inner {
        align-items: flex-end;
        display: flex;
        flex-wrap: wrap;

        .stock.unavailable {
            display: none;
        }
    }

    .product-item-link {
        padding: 0 0 $amsearch__indent;
        word-break: keep-all;
    }

    .action.tocart {
        @extend .am-word-break;

        border-radius: 0;
        font-size: 1.2rem;
        line-height: 1.2;
        min-height: 35px;
        padding: $amsearch__indent;
        white-space: inherit;
    }

    .product-item [data-role='priceBox'] {
        & {
            display: flex;
            flex-basis: 100%;
            flex-wrap: wrap;
            font-size: 16px;
            font-weight: 700;
            margin: 0;
            padding: 0 0 $amsearch__indent;
        }

        p,
        span {
            flex-basis: 100%;
            padding: 0 0 $amsearch__indent / 2;
        }
    }

    .product-reviews-summary {
        margin: 0;
        padding: 0 0 $amsearch__indent;
    }

    .actions-secondary {
        & {
            display: none !important;
            align-items: center;
            display: flex;
            padding: 5px;
        }

        .amsearch-button.-icon {
            margin: 5px;
        }

        > .action {
            flex-grow: inherit;
            line-height: normal;
            margin-right: 5px;
        }
    }
}

.amsearch-products-section.-list {
    .amsearch-item {
        width: 100%;
    }

    .amsearch-image {
        max-width: 132px;
        width: 40%;
    }

    .actions-secondary {
        padding: 0 0 0 $amsearch__indent / 2;
    }

    .amsearch-description {
        padding-left: $amsearch__indent;
    }
}

.amsearch-products-section.-grid {
    .amsearch-image {
        padding-bottom: $amsearch__indent;
        width: 100%;
    }

    &:not(.-slider) .amsearch-item {
        flex-wrap: wrap;
        max-width: 50%;
    }
}

//
//  Tablet
//  --------------------------------------------

@include min-screen($screen__m) {
    .amsearch-products-section {
        & {
            border-top: none;
            display: flex;
            flex-wrap: wrap;
        }

        &:not(:first-child) {
            border-left: 1px solid $amsearch-border__color;
        }

        .amsearch-description {
            width: auto;
        }
    }

    .amsearch-products-section.-list {
        .amsearch-image {
            max-width: 125px;
        }

        .product-item-actions {
            align-content: flex-start;
            display: flex;
        }

        .amsearch-description {
            padding-left: $amsearch__indent__l;
        }
    }

    .amsearch-products-section.-grid:not(.-slider) .amsearch-item {
        max-width: calc(100% / 3);
    }
}

//
//  Desktop
//  --------------------------------------------

@include min-screen($screen__l) {
    .amsearch-result-block:not(.-preload) .amsearch-products-section {
        width: calc(100% - #{$amsearch-sidebar__width});
    }

    .amsearch-products-section.-grid:not(.-slider) .amsearch-item {
        max-width: calc(100% / 4);
    }
}

//
//  Search Collapsible Styles
//  ______________________________________________

//
//  Common
//  ----------------------------------------------

.amsearch-collapsible-section[data-collapsible] {
    &.-opened .amsearch-collapsible-title:after {
        transform: rotate(225deg) translate(-5px, 0);
    }

    .amsearch-collapsible-title {
        & {
            align-items: center;
            cursor: pointer;
            display: flex;
        }

        &:after {
            border-bottom: 1px solid $amsearch-border__color;
            border-right: 1px solid $amsearch-border__color;
            content: '';
            display: inline-block;
            height: $amsearch__indent;
            transform: rotate(45deg) translate(0, -5px);
            transition: .3s all;
            width: 10px;
        }
    }
}

//
//  Search Popup Header Styles
//  ______________________________________________

//
//  Common
//  ----------------------------------------------

.amsearch-block-header {
    & {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
        padding: 0 $amsearch__indent__l 2px;
        width: 100%;
    }

    .amsearch-title {
        font-size: 1.8rem;
        font-weight: 700;
        text-transform: capitalize;
        word-break: break-word;
        word-wrap: break-word;
        line-height: 36px;
    }

    .amsearch-link {
        & {
            line-height: 26px;
            min-width: 90px;
            font-weight: 700;
            text-decoration: underline;
            background: #f5f5f5;
            border-radius: 18px;
            padding: 5px 20px;
        }

        &.-view-all .amsearch-count {
            font-weight: inherit;
        }
    }

    .amsearch-link .amsearch-count:before {
        content: '(+';
    }

    .amsearch-count {
        & {
            font-weight: 300;
        }

        &:before {
            content: '(';
        }

        &:after {
            content: ')';
        }
    }
}

//
//  Search Popup Tab item Styles
//  ______________________________________________

//
//  Common
//  ----------------------------------------------

.amsearch-category-tab .amsearch-item {
    & {
        font-size: 1.6rem;
        margin-bottom: $amsearch__indent;
    }

    .am-item-link:not(:last-of-type):after {
        background: $amsearch-icon__background__arrow-right;
        background-size: contain;
        content: '';
        display: inline-block;
        height: $amsearch__indent;
        margin: 0 $amsearch__indent;
        width: $amsearch__indent;
    }
}

.amsearch-result-tabs {
    & {
        margin-top: $amsearch__indent / 2;
        z-index: initial;
    }

    > .data.item.content:not([aria-hidden='false']) {
        display: none;
    }

    .title {
        & {
            box-sizing: border-box;
            position: relative;
        }

        &.active {
            .amsearch-results-count {
                display: none;
            }

            .switch {
                padding-bottom: 1px;
            }
        }
    }

    .data.content {
        display: none;
    }

    .data.content[aria-hidden='false'] {
        display: block;
    }

    .data.title {
        & {
            margin-right: $amsearch__indent / 2;
        }

        &.active {
            background: #d8d8d8;
            box-sizing: border-box;
        }

        &.last {
            margin-bottom: 8px;
        }
    }
}

//
//  Desktop
//  ---------------------------------------------

@include min-screen($screen__m) {
    .amsearch-result-tabs .title:hover,
    .amsearch-result-tabs .title.active {
        &:before {
            background: #fff;
            bottom: -1px;
            content: '';
            height: 2px;
            left: 1px;
            position: absolute;
            width: calc(100% - 2px);
        }
    }
}

//
//  Results Popup Styles
//  ______________________________________________

//
//  Common
//  ----------------------------------------------

.page-header {
    &:not(.amsearch-full-width) .amsearch-result-section {

    }

    .amsearch-result-section {
        right: 0;
    }
}

.amsearch-result-section {
    & {
        @include am-scrollbar();

        background-color: #fff;
        border: 1px solid #eeeeee;
        border-radius: 3px;
        box-shadow: 0 2px 4px rgba(84, 95, 125, .12), 0 5px 8px rgba(155, 159, 174, .1);
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        margin: 5px 0;
        max-height: 90vh;
        position: absolute;
        top: 100%;
        transition: .3s;
        width: 100%;
        z-index: 99;
    }

    &:empty {
        display: none;
    }

    .amsearch-result-block {
        & {
            box-sizing: border-box;
            max-width: 100%;
            width: 100%;
        }

        &:empty {
            display: none;
        }
    }
}

//
//  Mobile
//  ---------------------------------------------

@include min-screen($screen__m) {
    .amsearch-result-section {
        & {
            min-width: 100%;
            width: inherit;
        }

        &:not(.-small) {
            flex-wrap: nowrap;
        }
    }
}

//
//  Results Preload Popup Styles
//  ______________________________________________

//
//  Common
//  ----------------------------------------------

.amsearch-result-block.-preload {
    & {
        display: flex;
        flex-wrap: wrap;
    }

    &:empty {
        display: none;
    }

    .amsearch-item:first-child:last-child {
        max-width: 100%;
        width: 100%;
    }

    .amsearch-slider-section {
        width: 100%;
    }

    .amsearch-products-list {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

//
//  Mobile
//  ---------------------------------------------

@include min-screen($screen__m) {
    .amsearch-result-block.-preload {
        & {
            flex-wrap: nowrap;
        }

        .amsearch-products-list:not(:first-child) {
            border-left: 1px solid $amsearch-border__color;
            width: calc(100% - #{$amsearch-sidebar__width});
        }
    }
}

//
//  Search Input Styles
//  ______________________________________________

//
//  Common
//  ----------------------------------------------

.page-header .amsearch-input-wrapper {
    width: 100%;
}

.amsearch-input-wrapper {
    & {
        max-width: 100%;
        position: relative;
        transition: .3s;
    }

    &.-typed {
        .amsearch-button.-loupe {
            left: $amsearch__indent;
            right: auto;
        }

        .amsearch-input {
            padding-left: 33px;
            padding-right: 33px;
        }
    }

    .amsearch-input {
        & {
            border: 1px solid #ccc;
            padding: 5px 10px;
            transition: .3s;
        }

        &:focus {
            & {
                border: 1px solid $amsearch-secondary__color;
                box-shadow: none;
            }

            &::placeholder,
            &::-webkit-input-placeholder {
                color: transparent;
            }
        }

        &::placeholder,
        &::-webkit-input-placeholder {
            color: #999;
        }
    }

    .amsearch-button {
        &.-close {
            bottom: 0;
            font-size: 1rem;
            margin: auto;
            position: absolute;
            right: 0;
            top: 0;
        }

        &.-loupe {
            border: 0;
            bottom: 0;
            margin: auto;
            position: absolute;
            right: 5px;
            top: 0;
        }
    }
}

//
//  Mobile
//  ---------------------------------------------

@include min-screen($screen__m) {
    .page-header:not(.amsearch-full-width) .amsearch-input-wrapper.-dynamic-width {
        width: 100%;
    }

    .page-header .amsearch-input-wrapper {
        min-width: 283px;
        width: auto;
    }
}

//
//  Search Overlay Styles
//  ______________________________________________

//
//  Common
//  ----------------------------------------------

// start Fix for widgets
body.-amsearch-overlay-opened {
    .logo,
    .nav-sections {
        z-index: 0;
    }
}
// end Fix for widgets

.page-header.amsearch-full-width.-opened {
    z-index: 12;
}

.amsearch-overlay-block {
    & {
        background: rgba(0, 0, 0, .19);
        bottom: 0;
        display: none;
        height: 100%;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 11;
    }

    .amsearch-overlay {
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }
}

//
//  Category Item Styles
//  ______________________________________________

//
//  Common
//  ----------------------------------------------

.amsearch-items-section {
    & {
        padding: $amsearch__indent__xl 0 0 0;
        width: 100%;
    }

    &:empty {
        display: none;
    }
}

.amsearch-item-container {
    & {
        flex-basis: 100%;
        padding: 0 0 $amsearch__indent__l;
        position: relative;
    }

    &.-opened {
        padding: 0 0 32px;
    }

    &.-page a.amsearch-link {
        color: var(--amsearch-color-text-hover, $amsearch-product-sought__background);
        font-weight: 600;
    }

    &.-recent_searches {
        &:not(.-closed) .amsearch-block-header {
            padding: 0 $amsearch__indent__l $amsearch__indent__m;
        }

        .amsearch-tab-items {
            padding: 0 $amsearch__indent__l;
        }

        .amsearch-item {
            & {
                background: #f5f5f5;
                border: 1px solid transparent;
                border-radius: 25px;
                box-sizing: border-box;
                display: inline-block;
                margin: 0 8px $amsearch__indent 0;
                order: 1;
                text-decoration: none;
            }

            &.-match {
                background-color: var(--amsearch-color-hover, $amsearch-item__background__hover);
            }

            .amsearch-link {
                padding: 6px 15px;
            }
        }

        .amasty-xsearch-num-results {
            display: none;
        }
    }

    &.-popular_searches .amasty-xsearch-num-results {
        display: none;
        font-size: 12px;
    }

    &.-brands .amasty-xsearch-num-results {
        color: #ccc;
        display: inline-block;
        float: none;
    }

    .amsearch-tab-items {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .amsearch-description {
        padding: 8px $amsearch__indent__l;
    }

    .amsearch-item {
        & {
            cursor: pointer;
            margin: 0;
            overflow: hidden;
            text-transform: capitalize;
        }

        .amsearch-link {
            & {
                color: var(--amsearch-color-text, $amsearch-product-sought__background);
                display: block;
                font-size: 1.4rem;
                font-weight: 400;
                outline: none;
                padding: 8px $amsearch__indent__l;
                text-decoration: none;
            }

            &:hover {
                background: var(--amsearch-color-hover, $amsearch-item__background__hover);
                color: var(--amsearch-color-text-hover, $amsearch-product-sought__background);
            }

            &:focus {
                background: var(--amsearch-color-hover-focus, $amsearch-item__background__hover);
                color: var(--amsearch-color-text-focus, $amsearch-product-sought__background);
            }
        }
    }
}

//
//  Mobile
//  ---------------------------------------------

@include min-screen($screen__m) {
    .amsearch-item-container {
        & {
            padding: 0 0 $amsearch__indent__xl;
        }

        .amsearch-link {
            padding: 5px $amsearch__indent__l;
        }
    }

    .amsearch-items-section {
        & {
            padding: $amsearch__indent__xl 0;
        }

        &:not(:first-child:last-child) {
            max-width: $amsearch-sidebar__width;
            width: 100%;
        }
    }
}

//
//  Related Terms Styles
//  ______________________________________________

//
//  Common
//  ----------------------------------------------

.amsearch-related-terms {
    & {
        display: flex;
        flex-wrap: wrap;
        margin: $amsearch__indent__l 0;
    }

    > .amsearch-title {
        font-weight: 700;
        margin: 0 0 $amsearch__indent__m 0;
        width: 100%;
    }

    > .amsearch-item {
        & {
            background: #d8eeff;
            border-radius: $amsearch__indent__l;
            color: #252525;
            font-size: 12px;
            line-height: 1.3;
            margin: 0 $amsearch__indent__m $amsearch__indent 0;
            padding: $amsearch__indent / 2 $amsearch__indent__m;
            transition: .1s;
        }

        &:hover {
            background: #b7e0ff;
        }

        &:hover,
        &:active {
            text-decoration: none;
        }
    }

    .amsearch-count {
        color: #757575;
    }
}

//
//  Search Type Full Width Styles
//  ______________________________________________

//
//  Variables
//  ----------------------------------------------

$amsearch-full-width-wrapper__padding: 4vw;

//
//  Common
//  ----------------------------------------------

$amsearch-full-width-wrapper__padding: 15px;

.amsearch-button.-full-close {
    display: none;
}

.page-header {
    .amsearch-button.-loupe-trigger {
        background-size: 20px;
        filter: brightness(75%);
        float: right;
        height: 20px;
        margin: 14px;
        width: 20px;
    }
}

.page-header.amsearch-full-width {
    .header.content {
        transition: none;
    }

    .amsearch-full_width-title {
        flex-basis: 100%;
        font-size: 2.6rem;
        font-weight: 300;
        margin: 0;
        padding: 0 0 $amsearch__indent__l 0;
        z-index: 15;
    }

    .amsearch-input-wrapper {
        flex-grow: 10;
        width: initial;
    }

    .amsearch-button.-search {
        flex-grow: 1;
    }

    .amsearch-wrapper-block {
        display: none;
    }

    .amsearch-form-block {
        flex-wrap: wrap;
        height: auto;
        justify-content: flex-start;
        min-width: 100%;
        padding: $amsearch__indent__l $amsearch-full-width-wrapper__padding;
        transition: none;
    }

    .amsearch-button.-full-close {
        bottom: 0;
        margin: $amsearch__indent__l;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 98;
    }

    .amsearch-result-section {
        border-radius: 0;
        border-top: 1px solid $amsearch-border__color;
        margin: 0;
        transition: none;
        width: 100%;
    }

    .amsearch-slider-section {
        max-width: none;
        width: 100%;
    }

    .amsearch-wrapper-block {
        background: #f5f5f5;
        height: 100%;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 14;
    }
}

.page-header.amsearch-full-width.-opened {
    & {
        height: 134px;
        transition: .1s;
    }

    .panel.wrapper {
        display: none;
    }

    .header.content {
        align-content: center;
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        max-width: 100%;
        padding: 0;
    }

    .minicart-wrapper {
        display: none;
    }

    .amsearch-wrapper-block {
        display: inline-block;
    }

    .amsearch-button.-full-close {
        display: inline-flex;
    }
}

//
//  Mobile
//  --------------------------------------------

@include min-screen($screen__m) {
    $amsearch-full-width-wrapper__padding: 4vw;

    .amsearch-full_width-title {
        display: none;
    }

    .page-header.amsearch-full-width.-opened {
        .ammenu-logo,
        .logo {
            margin: 0 auto 0 $amsearch-full-width-wrapper__padding;
            z-index: 11;
        }

        .amsearch-wrapper-block {
            z-index: 10;
        }

        .amsearch-button.-full-close {
            right: $amsearch-full-width-wrapper__padding;
        }
    }

    .page-header.amsearch-full-width {
        .amsearch-input-wrapper {
            flex-grow: initial;
        }

        .amsearch-button.-search {
            flex-grow: initial;
        }

        .amsearch-wrapper-block {
            background: #fff;
            display: inline-block;
        }

        &:not(.-opened) .amsearch-wrapper-block {
            position: static;
            width: auto;
        }

        .amsearch-form-block {
            height: 100%;
            justify-content: center;
            padding: 0;
        }

        .amsearch-result-section {
            padding: 0 calc(#{$amsearch-full-width-wrapper__padding} - 15px);
        }

        .amsearch-products-section.-grid:not(.-slider) .amsearch-item {
            max-width: calc(100% / 4);
        }

        .amsearch-products-section.-list .amsearch-item {
            width: 50%;
        }

        .amsearch-button.-full-close {
            margin: auto;
        }
    }

    .page-header .amsearch-button.-loupe-trigger {
        display: none;
    }
}

//
//  Desktop
//  --------------------------------------------

@include min-screen($screen__l) {
    .page-header.amsearch-full-width .amsearch-products-section.-grid:not(.-slider) .amsearch-item {
        max-width: calc(100% / 5);
    }
}

//
//  Search Page Styles
//  ______________________________________________

//
//  Desktop
//  ---------------------------------------------

@include min-screen($screen__m) {
    .amsearch-search-page.page-products .columns {
        z-index: 0;
    }
}

//
//  End
//  ______________________________________________
