.bundle-options-container .product-add-form {
    display: block;
}


.bundle-options-container .bundle-options-wrapper, .bundle-options-container .product-options-wrapper {
    float: none;
    width: 100%;
}

.bundle-options-container .block-bundle-summary {
    width: 100%;
    background: none;
    padding: 0px;
    float: none;
    
}

.bundle-options-wrapper p.required {
    display: none;
}

.bundle-options-wrapper {
    margin-top: 20px;
    .fieldset {
        margin-bottom: 20px;
    }
}

.qty-can-be-hidden {
    display: none;
}

.block-bundle-summary {
    .box-tocart {
        .fieldset {
            display: flex;
        }
    }

    .bundle-summary {
        display: none;
    }
}