
body {
  font-family: 'Open Sans', sans-serif !important;
}

.amlabel-position-wrapper {
    z-index: 2;
}

.panel.wrapper {

  background-color: #ededed;
  color: #ffffff;

  a {
    color: #ffffff;

    &:hover, &:focus {
      color: #efefef;
    }

  }
}

.wishlist-index-index .products-grid .product-item {
  position: relative;
}

.wishlist-index-index .products-grid .product-item .product-item-inner {
  padding-bottom: 55px;
}

.wishlist-index-index .products-grid .product-item .product-item-inner .box-tocart .product-item-actions {
  bottom: 25px;
}

.quantity-unit {
  display: none;
}

.panel.header {
  font-family: 'Open Sans', sans-serif;
  height: 18px;
  padding-top: 0;
  font-size: 12px;
  font-weight: 700;
  background: #ededed;
  padding: 0px !important;
}

.header.panel > .header.links > li {
  font-size: 11px;
  margin: 0 0 0 5px;
  padding: 1px 2px;
  text-transform: capitalize !important;
  font-weight: bold;
  color: #8c8c8c;
}

.header.panel > .header.links > li.welcome, .header.panel > .header.links > li a {
  line-height: 1;
  color: #8c8c8c;
}

.header .logo {
  width: 200px;
}

.nav-sections {
  background-color: #dadada;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 17px;

  .navigation ul {
    background-color: #dadada;

    a {
      color: #666666;
    }

  }

  .navigation {
    .level0 > .level-top {
      color: #ffffff;
    }
  }
}

.navigation .level0.active > .level-top, .navigation .level0.has-active > .level-top {
  border-color: #666;
  color: #333333;
}

.navigation .level0 .submenu .active > a {
  border-color: #666;
}

.header .logo {
  width: 200px;
}

.page-footer {
  background-color: #f1f1f1;
  color: rgba(0,0,0,0.5);
  font-size: 1.6rem;

  a {
    color: #666;

    &:hover, &:focus {
      color: darken(#666, 10%);
    }
  }
}

.footer small {
  font-size: 1.6rem;
  font-family: 'Roboto', sans-serif !important;
}

.page-title-wrapper h1 {
  color: #666;
  font-family: 'Open Sans', sans-serif !important;
}

.minicart-wrapper .action.showcart .counter.qty {
  background-color: #adb2b3;
}

.swatch-option.selected {
  outline: 2px solid #333;
  border: 1px solid #fff;
  color: #333;
}

.swatch-option.image:not(.disabled):hover, .swatch-option.color:not(.disabled):hover {
  outline: 2px solid #333;
  border: 1px solid #fff;
}

.action.primary {
  background-image: none;
  background: #666;
  border: 1px solid #666;
  font-family: 'Roboto', sans-serif !important;
  font-size: 17px;

  &:hover, &:focus {
    background: darken(#666, 10%);
    border-color: darken(#666, 20%);
  }

  span {
    color: #ffffff;
  }
}

.abs-product-link, .product-item-name, .product.name a {
  color: #333333;
  font-family: 'Roboto', sans-serif !important;
  font-size: 17px;
}

.action.tocompare {
  display: none !important;
}

.action.primary.tosample {
  line-height: 2.2rem;
  padding: 14px 17px;
  font-size: 1.8rem;
}

.page-products .products-grid .product-item {
  margin-left: calc((100% - 4 * 23.233%) / 3);
  padding: 0;
  width: 22.233%;
}

/* Mega Menu */

.section-item-content .menu-container .menu > ul > li:hover {
  background: none !important;
  color: #fff;
}

.all-add-button {
  .action.primary {
    color: #f2f2f2 !important;
  }
}

.multi-wishlist-bottom .bss-file-upload {
  color: #f2f2f2 !important;
}

/* Mega MENU */

.section-item-content .menu-container .menu > ul > li:hover {
  background: #f1f1f1;
  color: #fff;
}

.section-item-content .menu-container .menu > ul > li > a {
  display: block;
  color: #666;
  font-size: 14px;
  padding: 0.7em 1.8em;
  text-decoration: none;
  font-weight: 600 !important;
  font-family: 'Open Sans', sans-serif !important;
}

.section-item-content .menu-container .menu > ul > li:hover > a {
  color: #ffffff;
}

.section-item-content .menu-container .menu > ul > li.menu-dropdown-icon > a:before {
  display:none;
}

.section-item-content .menu-container .menu > ul > li ul li a:hover:before{
  display:none;
}

.section-item-content .menu-container .menu > ul > li ul li a:hover {
  color: #666 !important;
  padding: 0 0 0 0px !important;
}

.section-item-content .menu-container .menu > ul > li ul li a:before {
  display: none;
}

.table-checkout-shipping-method {
  width: 100% !important;
  margin-bottom: 20px;
}


/* Footer Styling */

.page.footer {
  background-color: #dadada !important;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  color: #666;
}

.footer.content {

  .footer-link-container {

    width: 100%;
    display: flex;

    @include max-screen($screen__s) {
      flex-direction: column;
    }

    .footer-link-block {

      width: 20%;
      margin-right: 3%;

      @include max-screen($screen__s) {
        width: 100%;
        margin: 0px;
      }

      h4 {
        text-decoration: underline;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        color: #666;
        margin-bottom: 10px;
      }

      li a {
        font-size:12px;
      }

    }

  }

  .footer-link-content {

    width: 40% !important;
    display: flex;
    padding-top: 30px;
    justify-content: right;

    @include max-screen($screen__s) {
        width: 100%;
        margin: 0px;
      }

    .delivery {
      margin-right: 60px;
      width: 260px;
      display: none;
    }

    .standard {
      text-align: center;
      width: 150px;
    }

    .brand-logo {
      margin-bottom: 35px;
      img {
        width: 170px;
      }
    }

    .tel a {
      font-size: 17px;
    }

    p {
      font-size: 1rem;
    }
  }

}

.footer small {
  font-size: 1.2rem;
}

.section-item-content .menu-container .menu > ul > li > ul > li h4 {
  font-size: 1.6rem;
}

.section-item-content .menu-container .menu .level3 li a {
  font-size: 1.4rem;
}

.section-item-content .menu-container .menu > ul > li ul li {
  padding: 0px 0 !important;
}

.bss-fastorde-bottom tr td .multiple-wishlist textarea {
  display: block !important;
}

.action.primary.tobackorder {
  padding: 16px 19px;
}

.section-item-content .menu-container .menu > ul > li.active > a {
    color: #fff !important;
}

.page-layout-1column {
  .sidebar-main {
    display: none;
  }
}

.product-items {
  .product-item {
    .product-item-inner {
      .actions-primary {
        .stock {
          display: none;
        }
      }
    }
  }
}

@include max-screen($screen__s) {

  .section-item-content .menu-container .menu > ul > li {
    width: 100%;
  }

  .nav-sections .header.links a, .nav-sections .header.links a:hover {
    color: #333333;
  }

  .page-products .products-grid .product-item {
    margin-left: calc((100% - 4 * 29%) / 3);
    padding: 0;
    width: 29%;
  }

  .footer.content .footer-link-container .footer-link-block {
    width: 100%;
  }

  .action.primary.tosample {
    width: 100%;
    margin-top: 10px;
  }

}

.price-breaks-wrapper {
  padding: 0;
}


.price-breaks-list {

  width: 60%;
  margin-bottom: 20px;
  display: inline-block;


  dt {
    float: left;
    width: 48%;
    text-align: left;
    padding: 1%;
    border-bottom: 1px solid #ccc;
    font-weight: normal;
  }

  dd {
    float: right;
    width: 48%;
    text-align: left;
    padding: 1%;
    border-bottom: 1px solid #ccc;
    font-weight: normal;
    margin-bottom: 5px;
  }

  .title-row {
    font-weight: bold;
  }
}

.section-item-content .menu-container {
  max-width: 1272px !important;
}

@media only screen and (min-width: 768px) {
  .page-header .header.panel {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .product.data.items > .item.title > .switch {
    height: 31px;
  }
}

.stock-title, .stock-config-holder {
  font-size: 2rem;
}

.section-item-content .menu-container .menu > ul {
  width: initial;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
}

.section-item-content .menu-container .menu > ul > li > a {
  display: block;
  color: #666 !important;
  font-size: 14px;
  padding: 0.7em 1.8em;
  text-decoration: none;
  font-weight: 600 !important;
  font-family: 'Open Sans', sans-serif !important;
}

.section-item-content .menu-container .menu > ul > li:hover > a {
  color: #fff !important;
}

.abs-product-link, .product-item-name, .product.name a, .product-item-name, .product.name a {
  min-height: 50px;
}

.pages .items {
  font-weight: 500;
}

.pages .items .current {
  font-weight: 700;
}

.item.product.product-item {
  position: relative;

  .product-item-details {
    padding-bottom: 55px;
  }

}

.products-grid .product-item-actions {
  position: absolute;
  bottom: 0;
}


.filter-options-title {
 cursor:pointer;
 font-weight:600;
 margin:0;
 overflow:hidden;
 padding:10px 40px 0 0px;
 position:relative;
 word-break:break-all;
 z-index:1;
 display:block;
 text-decoration:none
}

.filter-options-title:after {
 -webkit-font-smoothing:antialiased;
 -moz-osx-font-smoothing:grayscale;
 font-size:13px;
 line-height:inherit;
 color:inherit;
 content: "\f078";
font: normal normal normal 14px/1 FontAwesome;
 vertical-align:middle;
 display:inline-block;
 font-weight:normal;
 overflow:hidden;
 speak:none;
 text-align:center
}

.filter-options-title:after {
 position:absolute;
 right:13px;
 top:12px
}

.filter-options-title:hover {
 color:#000
}
.active>.filter-options-title:after {
 content:'\f077'
}
.filter-options-content {
 margin:0;
 padding:10px
}
.filter-options-content .item {
 line-height:1.5em;
 margin:10px 0
}
.filter-options-content .item input[type="checkbox"],
.filter-options-content .item input[type="radio"] {
    float: left;
    margin: 4px 5px 0 0;
}
.filter-options-content a {
 color:#494949;
 margin-left:-5px;
 margin-right:-5px;
 padding-left:5px;
 padding-right:7px
}
.filter-options-content a:hover {
 text-decoration:none
}

.filter-options-content .count {
 color:#757575;
 font-weight:300;
 padding-left:0px;
 padding-right:0px
}
.filter-options-content .count:before {
 content:'('
}
.filter-options-content .count:after {
 content:')'
}

/* Additional Styling */

.header.panel > .header.links > li.welcome, .header.panel > .header.links > li span.logged-in {
  line-height: 1.5;
}

@include max-screen($screen__s) {

  .column.main .product-items {
    margin: 0px !important;
  }

  .section-item-content .menu-container .menu > ul {
    display: block !important;
  }

  .page-products .products-grid .product-item {
    margin: 0px !important;
    width: 100%;
  }

  .page-products .product-item-info {
    width: 100% !important;
  }

}

#block-discount {
  display: none;
}

.action.primary {
  font-weight: 500 !important;
}

.header.panel > .header.links > li.welcome, .header.panel > .header.links > li a {
    line-height: 1;
    color: #333 !important;
}

.cms-fast-order .page-main .page-title-wrapper .page-title {
    color: #8c8c8c;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 40px !important;
    text-transform: uppercase;
}

.customer-account-login.page-layout-1column .section-item-content .menu-container .menu > ul > li > ul {
    display: none !important;
}

@include max-screen($screen__s) {
  .section-item-content .menu-container .menu > ul > li > ul {
    display: none !important;
  }
}

.section-item-content .menu-container .menu > ul > li ul.column4 > li {
  width: 20% !important;
}

@include max-screen($screen__s) {

  .panel.header .links {
    display: block;

    li {
      display: none;
    }

    li.authorization-link {
      display: block;
      text-align: right;
    }
  }
}

.header.panel > .header.links > li.welcome {
  line-height: initial !important;
  padding-left: 0px;
}

.header.panel > .header.links > li.welcome {
  display: none;
}

.section.section-trade {
  text-align: center;
  background-color: #dadada;
  margin-bottom: 20px;

  p {
    line-height: 37px;
    margin: 0px;
    font-size: 12px;

    a {
      &:hover {

      }
    }
  }
}

.section-item-content .menu-container .menu > ul > li:hover > a {
    color: #333 !important;
}

@include min-screen($screen__l) {
  .section-item-content .menu-container .menu > ul > li > a {
    font-size: 16px !important;
    font-weight: normal !important;
  }
}

@include max-screen($screen__l) {
  .section-item-content .menu-container .menu > ul > li > a {
    padding-left: 0.4rem !important;
    padding-right: 0.4rem !important;
  }
}

.header .logo {
  margin-left: 40%;

  @include max-screen($screen__s) {
    margin-left: 27%;
  }
}

.page-header {
  @include max-screen($screen__s) {
    margin-bottom:0px;
  }
}

.nav-sections {

  background-color: #fff !important;

  @include max-screen($screen__s) {
    background-color: #dadada !important;
  }
}

.footer.content .footer-link-content .standard {
  width: 270px;
  @include max-screen($screen__s) {
    width: 100%;
  }
}

.product-info-main .page-title-wrapper .page-title {
  font-size: 24px !important;
}

.catalog-product-view #maincontent {
  padding-top: 50px;
  @include max-screen($screen__s) {
    padding-top: 0px;
  }
}

.section-item-content .menu-container .menu > ul > li.active > a {
  color: #333333 !important;
}



.switch-bar {

  .panel.header {
    height: auto;
  }

  .account-switching {
    padding: 10px;
    color: #333;
    background: #fff;
  }

}

//
//  Table Breakdown
//  ---------------------------------------------

.data.table.breakdown tr:nth-of-type(2n+1) {
    background-color: #f5f5f5;
}

.data.table.breakdown tr td + td {
    text-align: right;
}

.data.table.breakdown tr:last-child td {
    font-weight: bold;
}

.data.table.breakdown a {
    text-decoration: underline;
}

.modal-popup.breakdown .modal-inner-wrap {
    max-width: 1240px;
}

.modal-popup.breakdown .modal-body-content {
    max-height: 500px;
    overflow-y: scroll;
}

//
//  Modal
//  ---------------------------------------------

.modal-popup {
    .modal-header {
        margin-bottom: 2.5rem;
    }
    .modal-title {
        border: 0;
    }
    .action-close {
        padding: 2.5rem 2rem;
    }
}


//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .modal-popup {
        &.modal-slide {
            .modal-header {
                @include lib-css(border-bottom, $modal-title__border);
            }
        }
    }
}
