.nav-sections {
  background-color: #efefef;

  .navigation ul {
    background-color: #efefef;
  }
}

.switch-bar.panel.wrapper {
  background-color: #ffffff;
  color: #ededed;
}

.panel.wrapper {

  background-color: #ededed;
  color: #ffffff;

  a {
    color: #ffffff;

    &:hover, &:focus {
      color: #efefef;
    }

  }
}

#account-selection .choices__inner {
  min-height: 22px;
}

.account-switching {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  #account-selection {
    display: flex;
  }

  p {
    font-weight: bold;
    margin-right: 20px;
    margin-bottom: 0px;
  }

  .choices {
    width: 350px;
    margin-right: 18px;
  }

  .action.tocart.primary {
    line-height: 26px;
    margoin-left: 20px;
  }

}

/* Category Listing */

.page-layout-2columns-left .sidebar-main {
  width: 25%;
  float: left;
  order: 1;
}

.page-layout-2columns-left .column.main {
  width: 75%;
  float: right;
  order: 2;
}


/* Footer */

.page-footer {
  border-top: 1px solid #d1d1d1;
}

.footer.content {
  border:none;
}

.panel.header {
  font-family: 'Open Sans', sans-serif;
  height: 18px;
  padding-top: 0;
  font-size: 12px;
  font-weight: 700;
  background: #ededed;
  padding: 0px !important;
}

.header.panel > .header.links > li {
  font-size: 11px;
  margin: 0 0 0 5px;
  color: #8c8c8c;
  padding: 1px 2px;
  text-transform: capitalize !important;
  font-weight: bold;
  color: #8c8c8c;
}

.header.panel > .header.links > li.welcome, .header.panel > .header.links > li a {
  line-height: 1;
  color: #8c8c8c;
}

.header .logo {
  width: 200px;
}