
.page-title-wrapper {
	h1 {

		color: #292E47;
	}
}

.homepage-banner-row {

	display: block;
	margin-bottom: 10px;

	@include min-screen($screen__s) {
		display: flex;
		height: 400px;
	}

	.text-section {
		width: 100%;
		min-width: 100%;
		text-align: center;
		align-items: center;
		justify-content: center;
		display: block;
		flex-direction: column;
		padding: 0 0px;
		margin-right: 0px;
		padding: 50px 0px;

		h2 {
			font-family: "rollerscript-smooth",sans-serif;
			font-weight: 500 !important;
		}

		p {
			margin-bottom: 10px;
		}

		br {
			margin-bottom: 10px;
		}

		a {
			background-color: #fff;
			padding: 10px 30px;
			margin-top: 20px;
			border-radius: 5px;
			display: inline-block;


			&:hover {
				text-decoration: none;
				background-color: darken(#fff, 10%);
			}
		}

		@include min-screen($screen__s) {
			width: 50%;
			min-width: 50%;
			padding: 0 50px;
			margin-right: 10px;
			display: flex;
		}

		@include min-screen($screen__m) {
			width: 30%;
			min-width: 30%;
		}
	}

	.banner-section {

		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			max-height: 300px;

			@include min-screen($screen__s) {
				max-height: initial;
			}
		}
	}

	&.yellow {

		.text-section {
			background: #988840;
			color: #fff;
		}

	}

	&.purple {

		.text-section {
			background: #653255;
			color: #fff;
		}

	}

	&.red {

		.text-section {
			background: #a45858;
			color: #fff;
		}

	}

	&.blue {

		.text-section {
			background: #0d3843;
			color: #fff;
		}

	}


	&.reverse {

		.text-section {
			order: 2;

			@include min-screen($screen__s) {

				margin-left: 10px;
				margin-right: 0px;

			}
		}

		.banner-secttion {
			order: 1;
		}
	}
}

.homepage-row {

	width: 100%;
	margin-bottom: 20px;

	h2 {
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		text-align: center;
		margin-bottom: 20px;
		margin-top: 20px;
	}

	h2:before,
	h2:after {
		content: '';
		border-top: 2px solid;
		margin: 0 20px 0 0;
		flex: 1 0 20px;
	}

	h2:after {
		margin: 0 0 0 20px;
	}

	.homepage-cols {
		display: flex;
		justify-content: space-between;
		margin: auto;

		@include max-screen($screen__s) {
			display: block;
		}

	}

	.homepage-col {

		width: 25%;

		@include max-screen($screen__s) {
			width:100%;
		}

		h2 {
			font-size: 14px;
			color: #666;
			font-weight: 600;
		}

		h2:before,
		h2:after {
			display:none;
		}

		img {
			width: 295px;
			height: 295px;
		}

		@include max-screen($screen__s) {
			img {
				width: initial;
				height: initial;
			}
		}

		p {
			font-size: 14px;
			font-weight: 500;
		}

		&+.homepage-col {
			margin-left: 20px;
			@include max-screen($screen__s) {
				margin-left: 0px;
				margin-bottom: 20px;
			}
		}
	}

}